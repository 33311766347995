/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default" style={{fontSize:12, fontWeight:"bold"}}>
        
        <Container>
          
          <nav>
            <ul>
              <li>
              <img
                alt="Femme-Forte Initiative"
                className="rounded-circle img-fluid img-raised"
                src={require("assets/img/femme-forte-gold.jpeg").default}
                width="50" 
                height="50"
              ></img>
              </li>
              <li>
              {/* <Link to="#about-us"> About Us</Link> */}
                <a
                  href=""
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#our-team"
                >
                  Our Team
                </a>
              </li>
              <li>
                <a
                  href="#our-projects"
                >
                  Our Projects
                </a>
              </li>
              <li>
                <a
                  href="#contact-us"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
          
        </Container>
        <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="r"
              target="_blank"
            >
              BREAKOUT.NG
            </a>
          </div>
      </footer>
    </>
  );
}

export default DefaultFooter;
